<template>
  <div class="page-content">
		<!-- Breadcrumb -->
		<nav class="page-breadcrumb" id="breadcrumb">
			<ol class="breadcrumb">
			<li class="breadcrumb-item">{{breadcrumbA}}</li>
			</ol>
		</nav>
		<!-- Page title -->
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
				<div class="card dash-card">
					<div class="card-body">
						<h4 class="dash-title mb-2">{{bienvenida}}</h4>
						<p class="dash-description">{{descripcion}}</p>
					</div>
				</div>
			</div>
                    
		</div>
	</div>
</template>

<script>
/*eslint-disable*/
import { capitalize, getDataFrom, capitalizeEach, isset } from "@/utils";
import APP_DATA from "@/env";

import firebase from "firebase/app";
import "firebase/database";
export default {
    name: 'Dashboard',
    data() {
        return {
			breadcrumbA: 'Dashboard',
            descripcion: APP_DATA.description,
			bienvenida: '',
		}
    },
	mounted() {
		const userData = JSON.parse(sessionStorage.getItem("userData"));

		var hora = new Date().getHours();

		if(hora >= 0 && hora < 12){
			this.bienvenida = "¡Buenos días";
		}else if(hora >= 12 && hora < 20){
			this.bienvenida = "¡Buenas tardes";
		}else { this.bienvenida = "¡Buenas noches"; }

		this.bienvenida += ` ${capitalize(userData.leg_nombre.split(' ')[0])}!`;


		// {
			// "a_cargo": true,
			// "emisor_dni": "12345678",
			// "emisor_nombre": "Yamila Agosta",
			// "equipamiento": "Burletes",
			// "estado": "Resuelto",
			// "motivo": "Auditoría ",
			// "observacion": "Provisoria, no se cambiaron. Se pegaron los mismos",
			// "operacion_id": "34",
			// "operacion_nombre": "BIOSIDUS BERNAL",
			// "resuelto": 1649080944665,
			// "timestamp": 1647458477627,
			// "tipo_motivo": "Rotura",
			// "updated": 1649080944665
		// }


		// emisor_dni
		// emisor_nombre van dentro de nodo applicant

		// equipamiento = equipment
		// motivo = description

		// "operacion_id": "34",
		// "operacion_nombre": "BIOSIDUS BERNAL",
		// // Cambiar idioma
		// "operation_id": "121",
		// "operation_name": "AGUAS DANONE", 

		// motivo = issue_type
		// timestamp = start_date
		// a_cargo = by_food

		// getDataFrom('https://fs-mantenimiento.firebaseio.com/tickets.json',false).then((data)=>{
		// 	// console.log(data);

		// 	Object.keys(data).forEach((i)=>{
		// 		let issue = data[i];

		// 		// let stateId = Math.random().toString(36).substring(2,7) + Date.now().toString(36);
		// 		let normalizedIssue = {
		// 			"applicant": {
		// 				"from_dni": issue.emisor_dni,
		// 				"from_name": capitalizeEach(issue.emisor_nombre),
		// 				"state": {
		// 					"color": "danger",
		// 					"label": "Sin asignar"
		// 				}
		// 			},
		// 			"by_food": issue.a_cargo,
		// 			"close_date": isset(issue.resuelto) ? issue.resuelto : "",
		// 			"start_date": issue.timestamp,
		// 			"description": issue.motivo,
		// 			"equipment": issue.equipamiento,
		// 			"issue_type": issue.tipo_motivo,
		// 			"operation_id": issue.operacion_id,
		// 			"operation_name": issue.operacion_nombre,
		// 			"state": {},
		// 			images: (isset(issue.imagenes)) ? issue.imagenes : [],
		// 		};


		// 		var newIssueRef = firebase.database().ref('issues').push();
		// 		let key = newIssueRef.key;
				
		// 		newIssueRef.update(normalizedIssue).then(()=>{
		// 			console.log('%cthe issue was successfully saved','color:#0f0');

		// 			let label;
	
		// 			if(issue.estado == 'Resuelto') label = "Cerrado";
		// 			if(issue.estado == 'En proceso') label = "Abierto";
		// 			if(issue.estado == 'En espera') label = "Abierto";
					
		// 			firebase.database().ref(`issues/${key}/state`).push({
		// 				'label':label,
		// 				'observation':(issue.observacion!=undefined) ? issue.observacion : "Sin observación",
		// 				'color': (issue.estado.toLowerCase()=='resuelto') ? 'success' : 'warning'
		// 			}).then(()=>{
		// 				console.log('%c issue state was saved','color:#0f0');
		// 			})
		// 		}).catch((err)=>reject(err));
		// 	})
		// })

		
		


		
	}
}
</script>

<style>
.dash-card {
	background-color: #00DBDE;
	background-image: linear-gradient(205deg, #00DBDE 0%, #FC00FF 100%);
	padding: 20px 0;
	color: #fff;
	border:none
}

.dash-title {
	font-size: 1.2rem;
	margin: 0;
}

.dash-description {
	margin: 0;
}
</style>