<template>
    <div :style="dimensions"></div>
</template>

<script>
export default {
    name: "Placeholder", 
    props:{
      width:{
        type:String,
        default:'100%'
      },
      height:{
        type:String,
        default:'30px'
      }
    },
    computed:{
      dimensions(){
        return `width:${this.width};height:${this.height}`
      }
    }
}
</script>

<style scoped>
@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}

div {
    border-radius: 6px;
    width: 100%;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: fff;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    /* height: 30px; */
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    left:0;
    right:0;
    top:0;
    bottom:0;
    transition: all .2s linear;
}
</style>
