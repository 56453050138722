<template>
  <h5 class="mb-3">
    <div class="d-flex-c-b">
      <div>
        <svg viewBox="0 0 24 24" width="16" height="16"  style="vertical-align: top;" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
        Gestion de bloques en: <u>{{audit.name}}</u>
      </div>

      <button class="btn btn-sm btn-primary" @click="add()" :disabled="current">
        Agregar bloque
        <svg viewBox="0 0 24 24" width="16" height="16" style="vertical-align: middle;" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
      </button>
    </div>
    <!-- <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg> -->
  </h5>

 

  <div class="row mb-3" v-if="newRequest">
    <div class="col">
      <div class="border rounded p-3">
        <label class="form-label">
          <!-- <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg> -->
          <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
          Nombre del nuevo bloque
        </label>
        <input type="text" class="form-control" v-model="newRequest.label" ref="labelInput">
        <div class="text-danger mt-1" v-if="error['newLabel']">&otimes; {{error['newLabel']}}</div>
        
        <div class="text-right mt-3">
          <div class="btn btn-light mr-2" @click.prevent="newRequest=null">Cancelar</div>
          <div class="btn btn-success" ref="submitButton" @click.prevent="save()">Crear bloque</div>
        </div>

      </div>
    </div>
  </div>

   <div class="row" v-if="!requests && !newRequest">
    <div class="col">
      <div class="alert alert-secondary">
        <div class="">
          No hay ningún bloque en esta auditoría.
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3" v-for="(request,r) in requests" :key="r" v-else>
    <div class="col">
      <div class="border rounded p-3" :class="(current == r)?'':''">
        <div class="mb-3 d-flex-c-b" :class="(current == r)?'border-bottom pb-2':''">
          <div>
            <button class="btn btn-link m-0 p-0 mr-2 text-dark pointer font-weight-bold" @click="current = (current==r)?null:r">
              <img v-if="request.icon" :src="request.icon" style="width:14px;height:14px">
              <svg v-else viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" style="vertical-align: sub;" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
              {{request.label}}
            </button>
            <button class="btn btn-xs btn-primary" @click="current = (current==r)?null:r">Editar bloque</button>
          </div>
          <div v-if="current == r">
            <button class="btn btn-xs btn-primary" v-if="!trigger.sorting" @click="addField()">
              Agregar campo
            </button>
            <div v-else>
              <div class="spinner-border" style="width:12px;height:12px" role="status">
                  <span class="sr-only">Loading...</span>
              </div>
              Guardando los cambios
            </div>
          </div>
        </div>

        <div class="border border-light p-2 rounded mb-3" v-if="current == r">
          <div class="d-flex-s-b">
            <div class="mr-4">
              <small>Icono del bloque</small>
              &nbsp;
              <input class="d-none" ref="inputFile" type="file" name="image" id="image" accept="image/*" @change="handleInputFile($event)">
              <button v-if="!request.icon" class="btn btn-xs btn-primary p-1" @click="$refs.inputFile[0].click()">
                <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
              </button>
              <img :src="request.icon" v-if="request.icon" @click="$refs.inputFile[0].click()" class="border border-light rounded p-1 pointer" style="width:32px;height:32px">
            </div>
            <div class="mr-4">
              <small>Prioridad del bloque</small>
              &nbsp;
              <label class="p-1 border border-light rounded mr-1 mb-0" for="1">
                <input type="radio" name="1" id="1" value="1" v-model="request.priority" @change="submitPriority()">
                1
              </label>
              <label class="p-1 border border-light rounded mr-1 mb-0" for="2">
                <input type="radio" name="2" id="2" value="2" v-model="request.priority" @change="submitPriority()">
                2
              </label>
              <label class="p-1 border border-light rounded mr-1 mb-0" for="3">
                <input type="radio" name="3" id="3" value="3" v-model="request.priority" @change="submitPriority()">
                3
              </label>
              <label class="p-1 border border-light rounded mr-1 mb-0" for="4">
                <input type="radio" name="4" id="4" value="4" v-model="request.priority" @change="submitPriority()">
                4
              </label>
            </div>
            <div class="">
              <small>Es bloque incumplimientos</small>
              &nbsp;
              <label class="p-1 border border-light rounded mr-1 mb-0" for="zero">
                <input type="checkbox" id="zero" :checked="request.is_zero" @change="handleZero($event,r)">
                Sí
              </label>
            </div>
            <div class="d-flex align-items-center" v-if="trigger.saving && current == r">
              <div class="spinner-border text-secondary" style="width:11px;height:11px" role="status">
                  <span class="sr-only">Loading...</span>
              </div> 
              &nbsp;
              <svg viewBox="0 0 24 24" width="16" height="16" stroke="#7987a1" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
            </div>

          </div>
          
          <div class="row align-items-center">
          </div>
        </div>

        <div class="alert alert-secondary" v-if="!request.fields">Este bloque no tiene ningún campo.</div>

        <div class="row">
          <div class="col" v-if="request.fields">
            <!-- List header -->
            <div class="row text-secondary">
              <div class="col-5">Consigna</div>
              <div class="col">Descripción</div>
              <div class="col-1">
                <popper arrow disableClickAway="false" placement="top" hover content="Es un campo condicional o condicionado">
                  <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
                </popper>
              </div>

              <div class="col-1">

                <popper arrow disableClickAway="false" placement="top" hover content="El campo acepta observaciones">
                  <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
                </popper>

              </div>
              <div class="col-1">

                <popper arrow disableClickAway="false" placement="top" hover content="El campo muestra información ilustrativa">
                  <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                </popper>

              </div>
              <div class="col-1"><svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></div>
            </div>

            <!-- fields List -->
            <draggable :list="list_fields" @change="log" v-if="current == r">
              <transition-group>
                <div class="row mt-2 bg-white" v-for="(field,f) in list_fields" :key="f">
                  <div class="col-5 d-flex">
                    <div class="text-ellipsis pointer" @click="handleUpdateField(field.id)">
                      {{field.label}} 
                    </div>
                    <div>
                      <popper arrow disableClickAway="false" placement="top" hover :content="field.label" >
                        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                      </popper>
                    </div>
                  </div>
                  <div class="col">
                    <popper arrow disableClickAway="false" placement="top" hover :content="field.description" >
                      <div class="text-ellipsis" style="width:120px">{{field.description}}</div>
                    </popper>
                    <!-- {{field.description}} -->
                  </div>
                  <div class="col-1">
                    <svg v-if="field.conditional_disabled" viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                    <svg v-else-if="field.conditional" viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><rect x="9" y="9" width="6" height="6"></rect></svg>
                    
                    <svg v-else viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle></svg>
                </div>
                  <div class="col-1">
                    <svg v-if="field.observation" viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                    <svg v-else viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle></svg>
                  </div>
                  <div class="col-1">
                    <svg v-if="field.extra_information" viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                    <svg v-else viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle></svg>
                  </div>
                  <div class="col-1">
                    <div class="badge badge-primary">{{field.score}}</div>
                  </div>
                </div>
              </transition-group>
            </draggable>

            <template v-else>
              <div class="row mt-2 bg-white" v-for="(field,f) in request.fields" :key="f">
                <div class="col-5 d-flex">
                  <div class="text-ellipsis">
                    {{field.label}}
                  </div>
                  <div>
                    <popper arrow disableClickAway="false" placement="top" hover :content="field.label" >
                      <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                    </popper>
                  </div>
                </div>
                <div class="col">
                  <popper arrow disableClickAway="false" placement="top" hover :content="field.description" >
                    <div class="text-ellipsis" style="width:120px">{{field.description}}</div>
                  </popper>
                </div>
                <div class="col-1">
                  <svg v-if="field.conditional_disabled" viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                  <svg v-else-if="field.conditional" viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><rect x="9" y="9" width="6" height="6"></rect></svg>
                  <svg v-else viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle></svg>
                </div>


                <div class="col-1">
                  <svg v-if="field.observation" viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                  <svg v-else viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle></svg>
                </div>
                <div class="col-1">
                  <svg v-if="field.extra_information" viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                  <svg v-else viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle></svg>
                </div>
                <div class="col-1">
                  <div class="badge badge-primary">{{field.score}}</div>
                </div>
              </div>
            </template>

          </div>
          <div class="col" v-if="(newField || updateField) && current == r">
            <!-- Form fields -->
            <div class="border rounded p-3 mb-3" v-if="newField">
              <h5 class="mb-3">
                <svg width="18" height="18" style="vertical-align:sub" fill="currentColor" class="bi bi-input-cursor-text" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M5 2a.5.5 0 0 1 .5-.5c.862 0 1.573.287 2.06.566.174.099.321.198.44.286.119-.088.266-.187.44-.286A4.165 4.165 0 0 1 10.5 1.5a.5.5 0 0 1 0 1c-.638 0-1.177.213-1.564.434a3.49 3.49 0 0 0-.436.294V7.5H9a.5.5 0 0 1 0 1h-.5v4.272c.1.08.248.187.436.294.387.221.926.434 1.564.434a.5.5 0 0 1 0 1 4.165 4.165 0 0 1-2.06-.566A4.561 4.561 0 0 1 8 13.65a4.561 4.561 0 0 1-.44.285 4.165 4.165 0 0 1-2.06.566.5.5 0 0 1 0-1c.638 0 1.177-.213 1.564-.434.188-.107.335-.214.436-.294V8.5H7a.5.5 0 0 1 0-1h.5V3.228a3.49 3.49 0 0 0-.436-.294A3.166 3.166 0 0 0 5.5 2.5.5.5 0 0 1 5 2z"/> <path d="M10 5h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4v1h4a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-4v1zM6 5V4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v-1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4z"/> </svg>
                Creando un nuevo campo
                </h5>
              <div class="form-row">
                <div class="col-12 col-md-6 mb-3">
                  <label class="form-label">Consigna</label>
                  <input type="text" class="form-control" v-model="newField.label">
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label class="form-label">Descripción</label>
                  <input type="text" class="form-control" v-model="newField.description">
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label class="form-label w-100">Observación</label>
                  <ToggleButton :border="true" :estadoInicial="newField.observation" @estado="newField.observation=$event" :estados="['Si, con observación','No, sín observaciónes']"/>
                </div>
                <div class="col-12 col-md-3 mb-3">
                    <label class="form-label">Puntaje</label>
                    <input type="text" class="form-control" v-model="newField.score">
                </div>
                <div class="col-12 col-md-3 mb-3">
                    <label class="form-label">Vencimiento</label>
                    <input type="text" class="form-control" placeholder="Inclumplimiento" v-model="newField.due_time">
                </div>
                <div class="col-12 pt-2">
                    <InputFile 
                      titulo="Agregar PDF o imágenes"
                      :multiple="true"
                      :update="newField.extra_information"
                      :pdf="true"
                      @seleccion="newField.extra_information = $event"
                    />
                </div>
                <div class="col-12 text-right mt-3">
                  <div class="btn btn-sm btn-light mr-2" @click="newField=null">Cancelar</div>
                  <button class="btn btn-sm btn-success" @click="saveField($event)">Crear campo</button>
                </div>
              </div>
            </div>
            <!-- Update form field -->
            <div class="border rounded p-3 mb-3" v-if="updateField">
              <h5 class="mb-3">
                <svg width="18" height="18" style="vertical-align:sub" fill="currentColor" class="bi bi-input-cursor-text" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M5 2a.5.5 0 0 1 .5-.5c.862 0 1.573.287 2.06.566.174.099.321.198.44.286.119-.088.266-.187.44-.286A4.165 4.165 0 0 1 10.5 1.5a.5.5 0 0 1 0 1c-.638 0-1.177.213-1.564.434a3.49 3.49 0 0 0-.436.294V7.5H9a.5.5 0 0 1 0 1h-.5v4.272c.1.08.248.187.436.294.387.221.926.434 1.564.434a.5.5 0 0 1 0 1 4.165 4.165 0 0 1-2.06-.566A4.561 4.561 0 0 1 8 13.65a4.561 4.561 0 0 1-.44.285 4.165 4.165 0 0 1-2.06.566.5.5 0 0 1 0-1c.638 0 1.177-.213 1.564-.434.188-.107.335-.214.436-.294V8.5H7a.5.5 0 0 1 0-1h.5V3.228a3.49 3.49 0 0 0-.436-.294A3.166 3.166 0 0 0 5.5 2.5.5.5 0 0 1 5 2z"/> <path d="M10 5h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4v1h4a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-4v1zM6 5V4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v-1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4z"/> </svg>
                Editando un campo
                </h5>
              <div class="form-row">
                <div class="col-12 col-md-6 mb-3">
                  <label class="form-label">Consigna</label>
                  <input type="text" class="form-control" v-model="updateField.label">
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label class="form-label">Descripción</label>
                  <input type="text" class="form-control" v-model="updateField.description">
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label class="form-label w-100">Campo con observación</label>
                  <ToggleButton :border="true" :estadoInicial="updateField.observation" @estado="updateField.observation=$event" :estados="['Si, con observación','No, sín observaciónes']"/>
                </div>
                <div class="col-12 col-md-3 mb-3">
                    <label class="form-label">Puntaje</label>
                    <input type="text" class="form-control" v-model="updateField.score">
                </div>
                <div class="col-12 col-md-3 mb-3">
                    <label class="form-label">Vencimiento</label>
                    <input type="text" class="form-control" placeholder="Inclumplimiento" v-model="updateField.due_time">
                </div>
                <div class="col-12 pt-2 mb-3">
                    <InputFile 
                      titulo="Agregar PDF o imágenes"
                      :multiple="true"
                      :update="updateField.extra_information"
                      :pdf="true"
                      @seleccion="updateField.extra_information = $event"
                    />
                </div>
                <div class="col-12" v-if="conditionalField() && !updateField.conditional">
                    <label class="form-label">Es condicionado por otro campo</label>
                    <ToggleButton :border="true" :estadoInicial="updateField.conditional_disabled" @estado="updateField.conditional_disabled=$event" :estados="['Si, este campo se deshabilita','No, el campo no se deshabilita']"/>
                </div>
                <div class="col-12" v-else>
                    <label class="form-label">Es un campo condicional</label>
                    <ToggleButton :border="true" :estadoInicial="updateField.conditional==true" @estado="updateField.conditional=$event" :estados="['Si, deshabilita otros campos','No, no modifica otros campos']"/>
                </div>
                <div class="col-12 text-right mt-3">
                  <div class="btn btn-sm btn-light mr-2" @click="updateField=null">Cancelar</div>
                  <button class="btn btn-sm btn-success" @click="submitFieldUpdate($event)">Editar campo</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";

import { isEmpty,getDataFrom,showError,triggerForm,putBase64 } from "@/utils";

import ToggleButton from '@/components/ToggleButton'
import InputFile from '@/components/InputFile'


import Popper from "vue3-popper";
import { VueDraggableNext } from 'vue-draggable-next'

export default {
    name:'ManageRequests',
    props:{audit:Object},
    components:{ToggleButton,Popper,InputFile,draggable:VueDraggableNext},
    data() {
      return {
        requests:null,
        current: null,
        current_field: null,
        newRequest: null,

        newField:null,
        updateField:null,

        error:{},

        trigger:{sorting:false,saving:false}
      }
    },
    computed:{
      list_fields(){
        if(!this.requests[this.current] || !this.requests[this.current].fields) return []

        let sorted_keys = Object.keys(this.requests[this.current].fields).sort((a,b)=>this.requests[this.current].fields[a].order - this.requests[this.current].fields[b].order)
        
        return sorted_keys.map((id)=>{
          return {...{id:id},...this.requests[this.current].fields[id]}
        })
      }
    },

    mounted(){
      console.log(this.audit);
      if(this.audit.requests){
        this.requests = this.audit.requests
        // this.$forceUpdate();
      }
    },

    methods: {
      handleZero(state,r){
        console.log('state',state,'r',r);

        this.trigger.saving = true
        Object.keys(this.requests).forEach((r_id)=>{
          this.requests[r_id]['is_zero'] = (r_id == r) ? state.target.checked : false
        })

        firebase.database().ref(`audits/${this.audit.id}/requests`).update(this.requests).then(()=>{
            this.trigger.saving = false
        }).catch((e)=>{showError(e,"No fué posible actualizar el bloque 0");this.trigger.saving = false})
      },
      conditionalField(){
        return Object.keys(this.requests[this.current].fields).some((f)=>
          this.requests[this.current].fields[f].conditional
        );
      },
      add(){
        this.newRequest = {label:null}
      },
      addField(){
        this.newField = {label:null,observation:true,description:"Check SI - NO",extra_information:null,score:null}
      },
      handleUpdateField(id){
        console.log('se va a actualizar el campo',id);

        this.updateField = null;
        this.current_field = null;

        this.$nextTick(()=>{
          this.updateField = JSON.parse(JSON.stringify(this.requests[this.current].fields[id]))
          this.current_field = id;
        })
      },
      submitFieldUpdate(event){
        event.target.innerHTML = `
            <div class="spinner-border" style="width:12px;height:12px" role="status">
                <span class="sr-only">Loading...</span>
            </div> Guardando
        `
        event.target.disabled = true;

        firebase.database().ref(`audits/${this.audit.id}/requests/${this.current}/fields/${this.current_field}`).update(this.updateField)
        .then(()=>{
          getDataFrom(`audits/${this.audit.id}/requests/${this.current}/fields`,true).then((r)=>{
            this.requests[this.current].fields = r
            this.current_field = null
            this.updateField = null
          })
        }).catch((e)=>showError(e,'No fué posible editar el campo'))
      },
      saveField(event){
        // Se actualiza la vista para mostrar es estado de carga
        event.target.innerHTML = `
            <div class="spinner-border" style="width:12px;height:12px" role="status">
                <span class="sr-only">Loading...</span>
            </div> Creando
        `
        event.target.disabled = true;


        if(this.requests[this.current].fields == undefined || this.requests[this.current].fields == null) {
          this.requests[this.current].fields = {}
        }

        let len = Object.keys(this.requests[this.current].fields).length
        this.newField.order = len

        firebase.database().ref(`audits/${this.audit.id}/requests/${this.current}/fields`).push(this.newField)
        .then(()=>{
          getDataFrom(`audits/${this.audit.id}/requests`,true).then((r)=>{
            this.requests = r
            this.newField = null
          })
        }).catch((e)=>showError(e,'No fué posible crear el nuevo campo'))
      },
      save(){
        delete this.error['newLabel']

        if(isEmpty(this.newRequest.label)){
          this.error['newLabel'] = 'Este campo es obligatorio';
          return null
        }

        triggerForm(this.$refs.submitButton,'Creando')

        firebase.database().ref(`audits/${this.audit.id}/requests`).push(this.newRequest)
        .then(()=>{
          getDataFrom(`audits/${this.audit.id}/requests`,true).then((data)=>{
            this.newRequest = null
            this.requests = data
          }).catch((e)=>showError(e,'No pudimos actualizar la información'))
        }).catch((e)=>showError(e,'No pudimos guardar el nuevo bloque'))
      },
      log(event) {
        this.trigger.sorting = true;

        // console.log(event)
        // console.log('fields',this.requests[this.current].fields);

        let fields = this.requests[this.current].fields;
        let target = Object.keys(fields).find((i)=>fields[i].order == event.moved.newIndex) // A target se le asignara oldIndex
        let moved = Object.keys(fields).find((i)=>fields[i].order == event.moved.oldIndex) // A moved se le asignara newIndex

        fields[target].order = event.moved.oldIndex;
        fields[moved].order = event.moved.newIndex;

        firebase.database().ref(`audits/${this.audit.id}/requests/${this.current}/fields/${target}`)
        .update({order:fields[target].order})
        .then(()=>{

          firebase.database().ref(`audits/${this.audit.id}/requests/${this.current}/fields/${moved}`)
          .update({order:fields[moved].order})
          .then(()=>{
            this.trigger.sorting = false;
          }).catch((e)=>showError(e,'No fué posible actualizar el orden'))

        }).catch((e)=>showError(e,'No fué posible actualizar el orden'))
        // console.log('result',JSON.parse(JSON.stringify(fields)));
      },
      handleInputFile(event){
        console.log(event)

        this.trigger.saving = true

        let file = event.target.files[0]

        let reader = new FileReader()
        reader.readAsDataURL(file)

        let $vue = this
        reader.onloadend = function() {
          putBase64(reader.result).then((url)=>{
            firebase.database().ref(`audits/${$vue.audit.id}/requests/${$vue.current}`)
            .update({icon:url})
            .then(()=>{
              $vue.requests[$vue.current].icon = url
              $vue.trigger.saving = false
            }).catch((e)=>{showError(e,"No fué posible guardar la imagen");this.trigger.saving = false})
          })
        }
        
        
      },
      submitPriority(){
        this.trigger.saving = true
        
        firebase.database().ref(`audits/${this.audit.id}/requests/${this.current}`)
        .update({priority:this.requests[this.current].priority})
        .then(()=>{
          this.trigger.saving = false
          
        }).catch((e)=>{showError(e,"No fué posible actualizar la prioridad");this.trigger.saving = false})
      }
    },
    
}
</script>

<style scoped>
.popper{
  --popper-theme-padding: 12px
}
</style>