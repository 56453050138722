const APP_DATA = {
    name:'bromatologia',
    env:'production',
    platform_module: "Bromatologia", // null (genera un bypass en login para cualquier usuario en nómina) // comprueba en routes/index.js y en Login
    db_url:{
        production: 'https://bromatologia-fs.firebaseio.com',
        develop: 'https://bromatologia-5c2c2-default-rtdb.firebaseio.com/'
    },
    db_config:{
        production: {
           apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
              authDomain: "https://bromatologia-fs.firebaseio.com",
              databaseURL: "https://bromatologia-fs.firebaseio.com",
              projectId: "cantina-89c6a",
              storageBucket: "cantina-89c6a.appspot.com",
              messagingSenderId: "353062217145",
              appId: "1:353062217145:web:8849a49a1ec52077"
        },
        develop: {
            apiKey: "AIzaSyAzUSOrp0d-MPdyZTfEebbkqGT4PXC0II0",
            authDomain: "bromatologia-5c2c2.firebaseapp.com",
            databaseURL: "https://bromatologia-5c2c2-default-rtdb.firebaseio.com",
            projectId: "bromatologia-5c2c2",
            storageBucket: "bromatologia-5c2c2.appspot.com",
            messagingSenderId: "823401463627",
            appId: "1:823401463627:web:4aa81e96f1960c7746f2ce"
        }
    },
    url: 'https://bromatologia.foodservice.com.ar',
    description: 'Esta aplicación te permite generar auditorias, ver reportes y realizar seguimiento e las mismas.',
}

export default APP_DATA;
  
export var tableConfig = {
    "language": {
        "decimal": ",",
        "thousands": ".",
        "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
        "infoPostFix": "",
        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
        "loadingRecords": "Cargando...",
        "lengthMenu": "Mostrar _MENU_ registros",
        "paginate": {
            "first": "Primero",
            "last": "Último",
            "next": "Siguiente",
            "previous": "Anterior"
        },
        "processing": "Procesando...",
        "search": "Buscar:",
        "searchPlaceholder": "Término de búsqueda",
        "zeroRecords": "No se encontraron resultados",
        "emptyTable": "Ningún dato disponible en esta tabla",
        "aria": {
            "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
            "sortDescending": ": Activar para ordenar la columna de manera descendente"
        },
    },
    "scrollX": false, 
    "search": {regex:true},
    dom: 'Bfrtip',
    responsive: false,
    nowrap: false,
    buttons: [
        'copy',
        'excel',
        'csv',
        'pdf'
    ]
};
  