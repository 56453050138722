<template>
  <transition name="modal">
    <div class="modal-mask" @click="outsideClick($event)">
      <div class="modal-wrapper">
        <div class="modal-container py-1" :class="{'modal-image':(modalImage!=undefined && modalImage==true)}" :style="setWidth(maxWidth)">
          <!-- <button class="btn btn-light" @click.prevent="$emit('close',false)">Cerrar</button> -->
          <div class="modal-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {modalImage:Boolean,maxWidth:String},
  methods: {
    outsideClick(e){
      // console.log(e);
      if(typeof e.target.className == "string"){
        if(e.target.className.includes('modal-wrapper')){
          this.$emit('close',false);
        }
      }
    },
    setWidth(maxWidth){
      if(maxWidth!=undefined && maxWidth!=null && maxWidth.length) return ("width:" + maxWidth);
      return "width: 60%";
    }
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  /* width: 60%; */
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px #00000016;
  transition: all 0.3s ease;
  font-size: 16px;
}

.modal-body {
  margin: 20px 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-image {
  background: none;
  padding:0;
}
</style>
