<template>
  <form v-if="auditor">
    <div class="form-row mb-2">
        <div class="col">
            <div class="label">Nombre y apellido</div>
            <input type="text" class="form-control" disabled v-model="auditor.name">
        </div>
        <div class="col">
            <div class="label">Documento</div>
            <input type="text" class="form-control" disabled v-model="auditor.dni">
        </div>
        <div class="col">
            <div class="label">Legajo</div>
            <input type="text" class="form-control" disabled v-model="auditor.legajo">
        </div>
    </div>
    <div class="form-row mb-2">
        <div class="col">
            <div class="label">Correo electrónico</div>
            <input type="text" class="form-control" v-model="auditor.mail">
            <div class="mt-1 text-danger" v-if="error.mail">&otimes; {{error.mail}}</div>
        </div>
    </div>
    <div class="text-right">
        <button class="btn btn-success" @click.prevent="submit($event)">
            Actualizar
        </button>
    </div>
  </form>
</template>

<script>
import {is_mail, isEmpty} from '@/utils'

import firebase from "firebase/app";
import "firebase/database";

export default {
    props:{
        selected:Object
    },
    data() {
        return {
            auditor:null,
            error:{}
        }
    },
    mounted() {
        this.auditor = JSON.parse(JSON.stringify(this.selected))
    },
    methods: {
        submit(e){
            e.target.innerHTML = `
                <div class="spinner-border" style="width:12px;height:12px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> Actualizando
            `;

            delete this.error['mail']

            if(isEmpty(this.auditor.mail)){
                this.error['mail'] = 'El correo es obligatorio'
                e.target.innerHTML = 'Actualizar'
                return null
            }

            if(!is_mail(this.auditor.mail)){
                this.error['mail'] = 'La estructura debe ser n0mbr3@dominio.com'
                e.target.innerHTML = 'Actualizar'
                return null
            }

            firebase.database().ref('auditors/'+this.selected.id).update(this.auditor).then(()=>{
                this.$emit('ended',this.auditor)
            })
        }
    },
}
</script>

<style>

</style>