<template>
  <form @submit.prevent="handleSubmit()">
    <div class="row">
        <div class="mb-2" :class="(field.type=='api')?'col-12':'col'" v-for="(field,f) in what" :key="f">
            <label class="form-label">{{field.label}}{{(field.validate)?'*':''}}</label>
            <input type="text" class="form-control" v-if="field.type=='text'" v-model="entity[field['name']]">
            <input type="date" class="form-control" v-if="field.type=='date'" v-model="entity[field['name']]">
            <input type="number" class="form-control" v-if="field.type=='number'" v-model="entity[field['name']]">
            <input type="mail" class="form-control" v-if="field.type=='mail'" v-model="entity[field['name']]">

            <template v-if="field.type=='api'">
                <multiselect
                    v-if="apis[field.api]"
                    mode="single"
                    v-model="selected_value"
                    :options="apis[field.api]"
                    :trackBy="'label'"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    class="multiselect-blue"
                    :placeholder="'Puede buscar y seleccionar un elemento'"
                    @select="handleAPISelection(field.props,entity)"
                ></multiselect>
                <Placeholder :height="'36px'" v-else/>
            </template>

            <div class="text-danger mt-1" v-if="error[field.name]">&otimes; {{error[field.name]}}</div>
        </div>
    </div>

    <div class="form-row mt-3">
        <div class="col text-right">
            <button class="btn btn-light mr-3" @click.prevent="$emit('ended',false)">Cancelar</button>
            <button class="btn btn-primary" ref="submitButton" @click.prevent="handleSubmit()">Guardar</button>
        </div>
    </div>

  </form>
</template>

<script>
import Multiselect from  '@vueform/multiselect'
import Placeholder from '@/components/Placeholder'
import {isEmpty,showError,triggerForm,getDataFrom,is_mail} from '@/utils'
import firebase from "firebase/app";
import "firebase/database";

export default {
    name:'Create',
    components:{Multiselect,Placeholder},
    props:{
        what:Array,
        where:String,
        timestamp:String // name of prop where stamp time
    },
    data() {
        return {
            entity:{},
            error:{},
            apis:{},
            selected_value:null
        }
    },

    mounted(){
        let promises = []
        this.what.forEach((field)=>{
            if(field['type'] == 'api'){

                promises.push(
                    getDataFrom(field['api']).then((data)=>{
                        this.apis[field['api']] = []
                        data.forEach((e)=>{
                            this.apis[field['api']].push({
                                'label':e.leg_apellido + " " + e.leg_nombre + " (DNI: " + e.leg_numdoc + ")", 
                                'value':e
                            })
                        })
                    }).catch((e)=>showError(e,'No fue posible obtener la nómina'))
                )

                Object.keys(field['props']).forEach((api_prop)=>{
                    // // if "+" split string and set
                    // api_prop.split('+').forEach((p)=>{
                    //     this.entity[p] = null;
                    // })
                    this.entity[field['props'][api_prop]] = null;
                })
            }else {
                this.entity[field['name']] = null;
            }
        })

        Promise.all(promises).then(()=>{
            console.log('todo liso');
        })
    },

    methods: {
        handleSubmit(){
            if(!this.valid()) return null;

            triggerForm(this.$refs.submitButton,'Creando')

            if(this.timestamp){
                this.entity[this.timestamp] = Date.now()
            }

            
            firebase.database().ref(this.where).push(this.entity)
            .then(()=>{
                this.$emit('created',this.entity);
            }).catch((e)=>showError(e,'Ocurrio un error al guardar la información'))
            

        },
        valid(){
            let valid = true;
            this.what.forEach((field)=>{
                delete this.error[field['name']];
                    console.log('entra a validar field');

                if(field['validate']){
                    if (isEmpty(this.entity[field['name']])) {
                        this.error[field['name']] = 'Es obligatorio'
                        valid = false;
                    }
                    
                    if(field['validate'] == 'mail'){
                        console.log('entra a validar mail');
                        if(!is_mail(this.entity[field['name']])){
                            this.error[field['name']] = 'La estructura es n0mbr3@dominio.com'
                            valid = false;
                        }
                    }

                    
                }
            })

            return valid;
        },
        handleAPISelection(props,entity){
            // console.log('data',this.selected_value);
            // console.log('props',props);
            // console.log('entity',entity);

            Object.keys(props).forEach((p)=>{
                let r = "";

                p.split('+').forEach((pp)=>{
                    if(this.selected_value[pp]){
                    r += this.selected_value[pp] + " "
                    }
                })

                entity[props[p]] = r.trim();
            })



        }
    },

}
</script>
<style  src="@vueform/multiselect/themes/default.css"></style>

<style>
</style>