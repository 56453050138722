import { createWebHistory, createRouter } from "vue-router";
import APP_DATA from '@/env';

import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";

// import Empty from "@/views/Empty.vue";
import Audit from "@/views/Audit.vue"
import Auditors from "@/views/Auditors.vue"
import ReportNonCompliance from "@/views/ReportNonCompliance.vue"
import OperationsReport from "@/views/OperationsReport.vue"

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  // {
  //   path: "/empty",
  //   name: "Empty",
  //   component: Empty,
  // },
  {
    path: "/auditorias",
    name: "Audit",
    component: Audit,
  },
  {
    path: "/auditors",
    name: "Auditors",
    component: Auditors,
  },
  {
    path: "/incumplimientos",
    name: "ReportNonCompliance",
    component: ReportNonCompliance,
  },
  {
    path: "/reporte",
    name: "OperationsReport",
    component: OperationsReport,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

function isAuthenticated(){
  let modulo = APP_DATA.platform_module;
  // Bypass (TODO EMPLEADO EN NOMINA)
  if(modulo==null && sessionStorage.getItem("userData") != null) return true;
  // Si existe un modulo asignado en APP_DATA se comprueba
  if (sessionStorage.getItem('userPermisos')) {
    if (JSON.parse(sessionStorage.getItem('userPermisos')).find((record) => record.ModulosAccess === modulo)) {
      var status =  JSON.parse(sessionStorage.getItem('userPermisos')).find( record => record.ModulosAccess === modulo).Lector;
      return status;
    }else{
      return false;
    }
  }
  return false
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'hash',
  linkActiveClass: 'active'
});

/**
 * Es posible mejorar el middleware utilizando la propiedad meta de cada ruta, por ejemplo
 * routes = [
 *  {
 *    path: "/ruta/hacia/aca"
 *    meta: {
 *      public: true | false
 *      permission: user | admin | sudo
 *    }
 *  }
 * ]
 * 
 * De esta forma a traves del middleware es fáclmente detectable el tipo de usuario o url, así se comprueba el login o no 
 * y tambien se puede utilizar en las vistas para mostrar contenido o no
 */

router.beforeEach((to, from, next) => {
  // To bypass the login (public routes)
  // if(to.name == 'Metricas') next()
  
  if ((to.name !== 'Login') && !isAuthenticated()) next({ name: 'Login' })
  else next()
})

export default router;
