export function capitalize(word) {
    if(word[0] == undefined) return word
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export function capitalizeEach(word) {
    let s = word.split(' ').flatMap((w) => {
        if (isEmpty(w)) return []
        return capitalize(w.trim())
    });
    return s.join(' ');
}

export function is_mail(text){
    let regex = new RegExp('[a-z0-9]+@[a-z]+[.][a-z]{2,3}');
    return regex.test(text)
}

export function randomid(){
    return Math.floor(Math.random() * (1000 - 9999 + 1) + 9999).toString(32) + Date.now().toString(32);
}

/**
 * Define propiedades a objetos en caso de no existir, acorta las lineas al crear estas nuevas propiedades,
 * evitanto los parametros writable, enmumerable y configurable
 * @param obj objeto al cual se le agrega la propiedad
 * @param name nombre de la nueva propiedad
 * @param val valor que se setea en la propiedad
 */
 export function setProp(obj,name,val){
    if (!Object.prototype.hasOwnProperty.call(obj, name)) {
        Object.defineProperty(obj, name, {
        value: val,
        writable: true,
        enumerable: true,
        configurable: true
        });
    }
 }


 import axios from "axios";
 import APP_DATA from "@/env";

/**
 * Devulve en una promesa la entidad consultada a través de la api
 * @param {String} entity entidad buscada siguiendo path de firebase.
 * @param {String} firebase indica si la consulta es a la base de dato del proyecto. 
 * @returns una promesa resolve (data[] o null)
 */

export function getDataFrom(entity,firebase,query){
    return new Promise((resolve, reject) =>{
        let url;
        if(firebase!=undefined && firebase==true){
            // get firebase url
            url = APP_DATA.db_url[APP_DATA.env];
            // Set entity like: firebase.com/operaciones.json
            url += `/${entity}.json`;
            // If query is set, add the query: ../operaciones.json?orderBy="height"...
            if(query!=undefined && query.length) url += `?${query}`;
        }else{
            url = entity;
        }

        // console.log(url);

        axios.get(url).then((response)=>{
            // console.log(response);
            if(response.status == 200){
                resolve(response.data);
            }else{ reject(null); }
        })
        .catch((error)=>{
            reject(error);
        })
    })
}

/**
 * Comprueba que el valor tenga valor y no sea null
 * @param property de un objeto
 * @returns true si es vacio
 */
export function isEmpty(property){
    return property == null || property == '';
}
/**
 * Comprueba que la cadena dada no contenga nada mas que números
 * @param property un string a comprobar
 * @returns true si es numerico
 */
export function isNum(property){
    return /^\d+$/.test(property);
}



import firebase from "firebase/app";
import 'firebase/storage';

export function putBase64(data) {
    return new Promise((resolve,reject)=>{
        var storageRef = firebase.storage().ref().child(`bromatologia/${new Date().getTime()+'-'+Math.ceil(Math.random()*1000)}`);
        storageRef.putString(data, 'data_url').then(function(snapshot) {
            
            snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
                resolve(downloadURL);
            }).catch(e=>{reject(e);})

        });  
    })
}

export function removeFile(url,filetype) {
    return new Promise((resolve,reject)=>{
        let type = 'images';

        if(filetype!==undefined && filetype.length) type = filetype;
        

        let imageId = url.split(`${type}%2F`)[1].split('?alt')[0];
        var storageRef = firebase.storage().ref(`${type}/`);
        // Create a reference to the file to delete
        var file = storageRef.child(imageId);
    
        // Delete the file
        file.delete().then(function() {
            console.log(`Archivo: ${imageId} eliminado`);
            resolve(true)
        }).catch(function(error) {
            reject(error)
        // Uh-oh, an error occurred!
        });
    })

}

export function storageFile(file,name) {
    return new Promise((resolve,reject)=>{
        var storageRef = firebase.storage().ref().child(`bromatologia/${name}`);
        storageRef.put(file).then((snapshot) =>{
            snapshot.ref.getDownloadURL().then((downloadURL) => {
                console.log('File available at', downloadURL);
                resolve(downloadURL);
            }).catch(e=>{reject(e);})
        });
    })
}

/**
 * Convierte la fecha dada al formato utilizado en Argentina DD-MM-AAAA
 * @param {String} initDate fecha para transformar, puede ser un Date o un string con fecha valida
 * @returns String fecha formateada
 */
 export function dateFormat(initDate,includeYear){
    console.log('el date es',initDate);


    let date = new Date(initDate)

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year;
    if (includeYear==undefined) year = date.getFullYear()

    if(month < 10){
        if(day<10){
            return (includeYear!=undefined && !includeYear) ? (`0${day}-0${month}`) : (`0${day}-0${month}-${year}`)
        }
        return (includeYear!=undefined && !includeYear) ? (`${day}-0${month}`) : (`${day}-0${month}-${year}`)
    }else{
        if(day<10){
            return (includeYear!=undefined && !includeYear) ? (`0${day}-${month}`) : (`0${day}-${month}-${year}`)
        }
        return (includeYear!=undefined && !includeYear) ? (`${day}-${month}`) : (`${day}-${month}-${year}`)
    }
}

/**
 * Show error message, through an alert dialog and log in the console
 */
import Swal from "sweetalert2";
export function showError(error,message){
    message = (message!=undefined) ? message : "No pudimos obtener la información necesaria. Puede refrescar la página y reintentar, pero perderá todos los cambios actuales."
    console.error(error);
    Swal.fire({
        icon: 'error',
        title: 'Algo salió mal',
        text: message,
        footer: '<a href="mailto:desarrollo@foodservice.com.ar" target="_blank">Quiero notificar el error</a>',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Actualizar la página',
        cancelButtonText: 'No actualizar'
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.reload();
        }
    })
}


export function isset(e){
    return(e!=undefined && e!=null && e!="");
}

export function getUserBasicData(){
    let u = JSON.parse(sessionStorage.getItem("userData"));
    return {
        name: u.leg_apellido + " " + u.leg_nombre,
        dni: parseInt(u.leg_numdoc) 
    }
}

export function getToastElement(content,type){
    let element = document.createElement('div');
    element.classList.add('toast-me');
    
    
    if (type == 'success') {
        element.classList.add('toast-me-success');
        
        // Set icon andcontent
        element.innerHTML = `
            <div>
                <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
            </div>
            <div>
                ${content}
            </div>
        `;
        
    } else if(type=='info'){
        element.classList.add('toast-me-info');
        // Set icon andcontent
        element.innerHTML = `
            <div>
                <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
            </div>
            <div>
                ${content}
            </div>
            `

    } else if(type=='warning'){
        element.classList.add('toast-me-warning');
        // Set icon andcontent
        element.innerHTML = `
            <div>
                <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
            </div>
            <div>
                ${content}
            </div>
            `
    } else if(type=='danger'){
        element.classList.add('toast-me-danger');
        // Set icon andcontent
        element.innerHTML = `
            <div>
                <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
            </div>
            <div>
                ${content}
            </div>
            `
    } else if(type=='primary'){
        element.classList.add('toast-me-primary');
        // Set icon andcontent
        element.innerHTML = `
            <div>
                <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
            </div>
            <div>
                ${content}
            </div>
            `
    }

    element.onclick = () => {
        element.remove();
    }

    return element;

}

export function triggerForm(button,buttonLabel){
    // Se actualiza la vista para mostrar es estado de carga
    if(button.disabled == false){
        button.innerHTML = `
            <div class="spinner-border" style="width:12px;height:12px" role="status">
                <span class="sr-only">Loading...</span>
            </div> ${buttonLabel}
        `
        button.disabled = true;
        for (let input of document.querySelectorAll('form input,form select')) {
            input.disabled = true;
        }
    }else {
        button.innerHTML = buttonLabel

        button.disabled = false;
        for (let input of document.querySelectorAll('form input,form select')) {
            input.disabled = false;
        }
    }

}

/**
 * set a default value if the object does not have the property
 * @param {Object} o object to set default property
 * @param {String} p property to set
 * @param {*} v any type of value to set as object property
 */
export function setdefault(o,p,v) {
    if(o==null) o = {}
    if(o[p] == undefined) o[p] = v
}

// UP


