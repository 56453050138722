<template>
  <div class="page-content">
    <!-- Navegación superior tipo breadcrumb -->
    <nav class="page-breadcrumb mb-2" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <router-link :to="{ name: 'Dashboard' }" class="text-dark">Inicio</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ view.title }}
        </li>
      </ol>
    </nav>

    <!-- Card title section -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">

            <!-- Title and actions -->
            <div class="row no-gutters align-items-center mb-3">
              <div class="col-12 col-sm-6 col-md-7">
                <h4 class="mb-2 mb-sm-0">
                  <svg viewBox="0 0 24 24" width="24" height="24" style="vertical-align: sub;"  stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                  {{view.title}}
                </h4>
              </div>
              <div class="col-12 col-sm-6 col-md-5 text-left text-sm-right">
                <!-- <button type="button" class="btn btn-outline btn-outline-secondary" @click="triggering('categories')" v-if="!trigger.create && !trigger.categories">
                  Secondary
                </button> -->
                <button type="button" class="btn btn-primary ml-2" @click="triggering('create')" v-if="!trigger.create && !trigger.manage">
                  Nueva auditoría
                  <svg viewBox="0 0 24 24" width="18" height="18" style="vertical-align: middle;" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                
                </button>
                <button class="btn btn-light" v-if="trigger.create || trigger.manage" @click="triggering('list')">&larr; Regresar</button>
              </div>
            </div>

            <!-- Description -->
            <div class="row mb-4">
              <div class="col">
                <p v-if="view.description">{{view.description}}</p>
                <div class="alert alert-info w-100 mb-0" v-if="view.info">
                  {{view.info}}
                </div>
              </div>
            </div>

            <!-- Modules -->
            <div class="row">

              <div class="col" v-if="trigger.list">
                <List 
                  :data="audits"
                  :columns="[{
                    'label':'Nombre',
                    'trackBy':'name',
                  }]"
                  :actions="[{
                      'label':'Gestionar bloques',
                      'emitter':'manage',
                      'color':'primary'
                    }
                  ]"
                  @manage="handleEvent('manage',$event)"
                />
              </div>

              <div class="col mt-2" v-if="trigger.create">
                <h5 class="mb-2">
                  <svg viewBox="0 0 24 24" width="16" height="16" style="vertical-align: top;" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                  Crear nueva auditoría
                </h5>

                <Create
                  :what="[
                    {name:'name', type:'text',label:'Nombre',validate:'text'}
                  ]"
                  where="audits"
                  timestamp="created_at"
                  @ended="triggering('list')"
                  @created="dataUpdate().then(() => triggering('list'))"
                />
              </div>


              <div class="col mt-2" v-if="trigger.manage">
                <ManageRequests 
                  :audit="selected"
                />
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    
  
    <!-- Update -->
    <!-- <div class="row">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <tree-manager></tree-manager>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Update -->
    <!-- <div class="row" v-if="trigger.update">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <update 
              :groups="groups" 
              :selected="selection"
              :categories="categories"
              @edit="handleEvent('update',$event)" 
              @end="handleEnd($event)"
            >
            </update>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import {getDataFrom,showError} from '@/utils';

// import firebase from "firebase/app";
// import "firebase/database";

import Create from '@/components/Create.vue';
import ManageRequests from '@/components/Audit/ManageRequests.vue';
// import TreeManager from '@/components/Audit/TreeManager.vue';
import List from '@/components/List.vue';

export default {
  name: "Audit",
  components:{
    Create,
    List,
    ManageRequests,
    // TreeManager
    // Update,
  },
  data(){
    return {
      view:{title:'Auditorías',description:'Cree nuevas auditorías o agregué información en auditorías existentes.'},

      required_data:['audits'], // Object {prop,db} or String to get[db] and set this[prop]

      audits: null, // props

      trigger:{
        create:false,
        update:false,
        list:false,
        manage:false,
      },

      selected:null,
    }
  },
  mounted(){
    this.dataUpdate().then(() => this.triggering('list'))
  },
  methods:{
    triggering(active) {
      Object.keys(this.trigger).forEach((t) => {
        this.trigger[t] = false

        if(typeof active=='object' && !active.includes(t)) this.trigger[t] = true
        if(typeof active=='string' && active == t) this.trigger[t] = true
      });
    },
    dataUpdate(){
      let promises = this.required_data.map((req)=>{
        let db = req; let prop = req
        if (typeof req == 'object') {db = req['db']; prop = req['prop']}

        return getDataFrom(db,true).then((data)=>{
          if(data) this[prop]=data
        }).catch((err) => showError(err,'Hubo un error al obtener información. Presione CANCELAR para reintentar o ACEPTAR para recargar la página.'));
      });

      return Promise.all(promises)
    },
    handleEvent(action,selection){ 
      this.selected = selection; 
      this.triggering(action) 
    },
    handleEnd(update){
      if (update) {
        this.dataUpdate().then(() => this.triggering('list'))
      } else {
        this.triggering('list')
      }
    },
    handleDelete(handle,data){
      // Dejar que cada vista lo lleve a su forma este método
      // Quizas se podrían crear 'modes' en utils para eliminar de diferentes formar. Como soft, en cascada, etc
      console.log(handle,data);
    },
  }
}
</script>

<style>

</style>