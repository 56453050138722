<template>
  <nav class="sidebar">
    <div class="sidebar-header">
      <a href="/" class="sidebar-brand">
        <div class="badge badge-primary">FS</div>
        <div class="badge badge-white">{{app_data.name.toUpperCase()}}</div>
      </a>
      <div class="sidebar-toggler not-active">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="sidebar-body">
      <ul class="nav">
        <li class="nav-item">
          <router-link :to="{ name: 'Dashboard' }" class="nav-link">
            <i class="link-icon" data-feather="home"></i>
            <span class="link-title">Inicio</span>
          </router-link>
        </li>
        <template v-for="(category,c) in menus" :key="c">
          <li class="nav-item nav-category" >{{category.category_name}}</li>
          <template v-for="(item,i) in category.items" :key="i">
            <li class="nav-item" v-if="item.modulePermission==undefined || isEditor(item.modulePermission)">
              <router-link :to="{ name: item.view }" class="nav-link">
                <i class="link-icon" :data-feather="item.icon"></i>
                <span class="link-title">{{item.label}}</span>
              </router-link>
            </li>
          </template>
        </template>
        
        <!-- <li class="nav-item">
          <router-link :to="{ name: 'Tickets' }" class="nav-link">
            <i class="link-icon" data-feather="life-buoy"></i>
            <span class="link-title">Tickets</span>
          </router-link>
        </li> -->
      </ul>
    </div>
  </nav>
</template>

<script>
import APP_DATA from '@/env';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));
export default {
  name: 'Menu',
  data() {
    return {
      app_data:APP_DATA,
      menus:[
        // {
        //   view: "",
        //   label: "",
        //   icon: ""
        // },
        {
          category_name: "Gestión",
          items: [
            // {
            //   view: "States",
            //   label: "Estados",
            //   icon: "flag",
            //   modulePermission:"AdminRole"
            // },
            {
              view: "Audit",
              label: "Auditorias",
              icon: "clipboard",
            },
            {
              view: "Auditors",
              label: "Auditores",
              icon: "user",
            },
          ]
        },
        {
          category_name: "Reportes",
          items: [
            {
              view: "OperationsReport",
              label: "Operaciones",
              icon: "bar-chart-2",
            },
            {
              view: "ReportNonCompliance",
              label: "Incumplimientos",
              icon: "x-octagon",
            },
          ]
        },
      ]
        
    }
  },
  methods: {
    isEditor(modulo){
        if (userPermission.find( record => record.ModulosAccess === modulo)) {
          return userPermission.find( record => record.ModulosAccess === modulo).Editor;
        }else{
          return false;
        }
    },
  },
};


</script>

<style scoped>
.sidebar .sidebar-header .sidebar-brand{
    /* font-size: 1.2rem; */
    padding-top: 4px;
}
</style>
