<template>
    <div>
        <!-- <div class="d-flex align-items-center justify-content-between mb-3">
            <h4 class="">Asignando operaciones al técnico: <div class="badge badge-light">{{this.selected.name}}</div></h4>

            <div>
                <button class="btn btn-success" @click="submit()">
                    <svg viewBox="0 0 24 24" style="vertical-align: middle !important;" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
                    Guardar
                </button>
            </div>
        </div> -->
        <div>
            <div class="form-row">
                <div class="col">
                    <input type="text" class="form-control" v-model="filter_search" placeholder="Busque operaciones..">
                </div>
                <div class="col">
                    <input type="checkbox" class="d-none" id="only-selected">
                    <label 
                        class="checkbox-input" 
                        :class="{'text-success':filter_selected}"
                        for="only-selected" 
                        tabindex=0 
                        @keydown.space="triggerProp('filter_selected')" 
                        @click="triggerProp('filter_selected')"
                    >
                        <!-- On -->
                        <svg v-if="filter_selected" width="16" height="16" fill="#10b759" class="bi bi-toggle-on" viewBox="0 0 16 16"> <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/> </svg>
                        <!-- Off -->
                        <svg v-else width="16" height="16" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16"> <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/> </svg>
                        <div class="ml-2" style="padding-top: 2px;">Mostrar solo las seleccionadas</div>
                    </label>
                </div>
                <div class="col text-right">
                    <div class="btn btn-success w-100" @click="submit($event)">
                        <svg viewBox="0 0 24 24" style="vertical-align: middle !important;" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
                        Guardar
                    </div>
                </div>
            </div>

            <div class="border rounded p-3" style="height:50vh; overflow-y: scroll;">
                <div class="row">
                    <div class="col-12 col-md-6 pointer mb-3" v-for="(operation, o) in filteredOperations(operations)" :key="o">
                        <div 
                            class="border rounded p-2" 
                            :class="(ii_operation_dni[operation.id]) ? 'border-success text-success' : 'border-dark text-dark'"
                            @click="select_operation(operation.id)"
                        >
                            
                            <div class="d-flex align-items-center">
                                <!-- On -->
                                <svg v-if="ii_operation_dni[operation.id]" width="18" height="18" fill="#10b759" class="bi bi-toggle-on" viewBox="0 0 16 16"> <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/> </svg>
                                <!-- Off -->
                                <svg v-else width="18" height="18" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16"> <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/> </svg>

                                <div class="ml-3">
                                    <p>{{operation.name}}</p>
                                    <small v-if="ii_operation_dni[operation.id] && ii_dni_auditorid[ii_operation_dni[operation.id]] && auditors[ii_dni_auditorid[ii_operation_dni[operation.id]]]">{{auditors[ii_dni_auditorid[ii_operation_dni[operation.id]]].name}}</small>
                                    <small v-else>Sin asignar</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {  setdefault  } from "@/utils";
// import { getDataFrom, isset } from "@/utils";

import firebase from "firebase/app";
import "firebase/database";

export default {
    props:{operations:Object,dni:Object,auditor_operation:Object,auditors:Object},
    data() {
        return {
            // selectedOperations:{},
            // freeOperations:{},
            trigger: {
                onlySelected:false,
            },

            searchInput:null,

            filter_search:null,
            filter_selected:false,

            
            ii_operation_dni:{},
            ii_dni_auditorid:{}
        }
    },
    mounted() {
        console.log('ao',this.auditor_operation);
        this.ii_operation_dni = this.ii_values_to_key(this.auditor_operation)
        
        // Invert auditors dni
        if(this.auditors){
            Object.keys(this.auditors).forEach((a)=>{
                this.ii_dni_auditorid[this.auditors[a].dni] = a
            })
        }


        
        // getDataFrom('operations',true,`orderBy="technician_dni"&equalTo=${this.selected.dni}`).then((data)=>{
        //     this.selectedOperations = data;
        // });
        // console.log(this.operations);
        // getDataFrom('operations',true).then((assignedOperations)=>{
        //     if(assignedOperations){
        //         // Recorre operaciones en consumos
        //         this.operations.forEach((operation)=>{
        //             // Comprueba si la operacion iterada se encuentra asignada en assignedOpe.. (DB de mantenimiento)
        //             if(assignedOperations[operation.id]!=undefined){
        //                 // Si esta asignada al tecnico actual seleccionado
        //                 if(assignedOperations[operation.id].technician_dni == this.selected.dni){
        //                     // Se agrega en selectedOperations
        //                     this.selectedOperations[operation.id] = assignedOperations[operation.id];
        //                     // Y en operaciones libres (estas se listan)
        //                     this.freeOperations[operation.id] = operation;
        //                 }else {
        //                     // Operations de otros tecnicos no se listan
        //                     // console.log(operation.name,'esta asignada');
        //                 }
        //             }else {
        //                 // Si no fue asignada la operacion se guarda en operaciones libres para ser listada y seleccionada
        //                 this.freeOperations[operation.id] = operation;
        //             }
        //         })
        //     }else {
        //         // si no fue seteada ninguna operation (punto inicial)
        //         this.freeOperations = this.operations;
        //     }
        // });
    },
    methods:{
        ii_values_to_key(o){
            console.log('checking',o);
            if(!o) return {}

            let r = {}

            Object.keys(o).forEach((k)=>{
                Object.keys(o[k]).forEach((v)=>r[v]=k)
            })

            return r
        },
        select_operation(operation_id){
            if(this.ii_operation_dni[operation_id] == this.dni){
                delete this.ii_operation_dni[operation_id]
            }else {
                this.ii_operation_dni[operation_id] = this.dni
            }
        },
        submit(e){
            e.target.innerHTML = `
                <div class="spinner-border" style="width:12px;height:12px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> Guardando
            `
            console.log(this.ii_operation_dni);
            
            let r = {}
            Object.keys(this.ii_operation_dni).forEach((o)=>{
                setdefault(r,this.ii_operation_dni[o],{})
                let operation = this.operations.find((op)=> op.id == o)
                r[this.ii_operation_dni[o]][o] = {
                    operation_name:operation.name,
                    operation_id:operation.id
                }
            })

            firebase.database().ref(`auditor_operation`).update(r).then(()=>{
                console.log('%c operations has been updated','color:#0f0');
                this.$emit('ended',r);
            })
        },
        filteredOperations(operations){
            if (!this.filter_search && !this.filter_selected) return operations
            return this.filter_by(operations)
        },
        filter_by(operations){
            console.log('filtering');
            let value = (this.filter_search) ? this.filter_search.trim().toLowerCase() : '';
            let results = [];

            Object.keys(operations).forEach((o)=>{
                if(
                    (this.filter_selected && this.ii_operation_dni[operations[o].id] && this.ii_operation_dni[operations[o].id]==this.dni)
                    ||
                    ((this.filter_search) && 
                    operations[o].name.trim().toLowerCase().includes(value) || 
                    value.includes(operations[o].id))
                ){
                    results.push(operations[o]);
                }
            });

            return results;
        },
        triggerProp(prop){
            this[prop]=!this[prop]
            // console.log(this.trigger[prop]);
        },
        // handleCheckOperation(operation){
        //     if(this.selectedOperations[operation.id]==undefined){
        //         this.selectedOperations[operation.id] = {
        //             name: operation.name,
        //             id: operation.id,
        //             direccion: operation.direccion,
        //             localidad: operation.localidad,
        //             provincia: operation.provincia,

        //             technician_dni:parseInt(this.selected.dni),
        //             technician_name:this.selected.name,
        //         };
        //     }else {
        //         delete this.selectedOperations[operation.id];
        //     }

        //     this.$forceUpdate();
        // }
    }
}
</script>

<style>
.checkbox-input {
    /* display: inline-block; */
    width: 100%;
    padding: 0.375rem 1.75rem 0.4rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: none;
    border-radius: 0.25rem;
    height: calc(1.3em + 0.75rem);

    display: flex;
    align-items: center;
}

.checkbox-input:focus {
    outline-width: 0;
    outline-color: transparent;
    box-shadow: none;
    outline-style: none;

    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
}

.checkbox-input-border {
    border: 1px solid #282f3ae6 !important;
}

.checkbox-input-border-success {
    border-color: #10b759 !important;
    box-shadow: #10b75915 0 0 6px;
}

.checkbox-operation {
    width: 49%;
    margin-bottom: 1.3rem;
}

.checkbox-operation .checkbox-input{
    padding: 1.275rem 1.75rem 1.2rem 0.85rem;
}

.checkbox-operations-container {
    border: 1px solid #282f3a5b;
    border-radius: .25rem;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;

    height:60vh;
    overflow-y: scroll;
}
</style>