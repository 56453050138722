<template>
  <div class="page-content">
    <!-- Navegación superior tipo breadcrumb -->
    <nav class="page-breadcrumb mb-2" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <router-link :to="{ name: 'Dashboard' }" class="text-dark">Inicio</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ view.title }}
        </li>
      </ol>
    </nav>

    <!-- Card title section -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">

            <!-- Title and actions -->
            <div class="row no-gutters align-items-center mb-3">
              <div class="col-12 col-sm-6 col-md-7">
                <h4 class="mb-2 mb-sm-0">
                  <!-- <svg viewBox="0 0 24 24" width="24" height="24" style="vertical-align: top;" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg> -->
                  <svg viewBox="0 0 24 24" width="24" height="24" style="vertical-align: top;" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                  {{view.title}}
                </h4>
              </div>
              <div class="col-12 col-sm-6 col-md-5 text-left text-sm-right">
                <!-- <button type="button" class="btn btn-outline btn-outline-secondary" @click="triggering('categories')" v-if="!trigger.create && !trigger.categories">
                  Secondary
                </button> -->

                <!-- <button type="button" class="btn btn-primary ml-2" @click="triggering('create')" v-if="!trigger.create && !trigger.manage">
                  Nueva auditoría
                  <svg viewBox="0 0 24 24" width="18" height="18" style="vertical-align: middle;" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                
                </button>
                <button class="btn btn-light" v-if="trigger.create || trigger.manage" @click="triggering('list')">&larr; Regresar</button> -->
              </div>
            </div>

            <!-- Description -->
            <div class="row mb-4">
              <div class="col">
                <p v-if="view.description">{{view.description}}</p>
                <div class="alert alert-info w-100 mb-0" v-if="view.info">
                  {{view.info}}
                </div>
              </div>
            </div>

            <!-- Modules -->
            <div class="row">

              <!-- <div class="col" v-if="trigger.list">
                <List 
                  :data="audits_completed"
                  :columns="[
                    {
                      'label':'Operación',
                      'trackBy':'operation_name',
                      'format':'uppercase'
                    },
                    {
                      'label':'Fecha',
                      'trackBy':'start_at',
                      'type':'function',
                      'formatter':this.dateFormateR
                    },
                    {
                      'label':'Resultado',
                      'trackBy':'result',
                      'type':'badge',
                      'color':this.get_badge_color
                    },
                  ]"
                  :actions="[{
                      'label':'Ver en detalle',
                      'emitter':'detail',
                      'color':'primary'
                    }
                  ]"
                  @detail="handleAuditData($event)"
                />
              </div> -->

              <div class="col" v-if="trigger.list">
                <List 
                  :data="report_data"
                  :columns="[
                    {
                      'label':'Operación',
                      'trackBy':'operation_name',
                      'format':'uppercase'
                    },
                    {
                      'label':'Estado',
                      'trackBy':'state',
                      'type':'badge',
                      'color':this.get_badge_color
                    },
                    {
                      'label':'Incumplimientos',
                      'trackBy':'non_compliance',
                      'type':'badge'
                    },
                    {
                      'label':'Última actualización',
                      'trackBy':'last_update',
                      'type':'badge'
                    },
                  ]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  
    <!-- Update -->
    <!-- <div class="row">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <tree-manager></tree-manager>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Update -->
    <div class="row" v-if="trigger.show">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="text-right" @click="triggering('list')">
              <div class="btn btn-light">&larr; Volver atras</div>
            </div>
            <div id="table"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getDataFrom,showError,dateFormat} from '@/utils';

// import firebase from "firebase/app";
// import "firebase/database";

// import Create from '@/components/Create.vue';
// import ManageRequests from '@/components/Audit/ManageRequests.vue';
// import TreeManager from '@/components/Audit/TreeManager.vue';
import List from '@/components/List.vue';

export default {
  name: "OperationsReport",
  components:{
    List,
  },
  data(){
    return {
      view:{title:'Estado de las operaciones',description:'Conozca el estado de cada operación, resultado de la última auditoría e incumplimientos'},

      required_data:['audits_completed','non_compliance'], // Object {prop,db} or String to get[db] and set this[prop]

      audits_completed: null, // props
      non_compliance: null, // props
      operations:null,

      report_data: null,

      trigger:{
        list:false,
        show:false
      },

      selected:null,
    }
  },
  mounted(){
    this.dataUpdate().then(() => {
      this.report_data={}
  console.log(this.audits_completed);
      getDataFrom('https://consumos.foodservice.com.ar/api/empresas').then((data)=>{
        this.operations = {}
        data.forEach((operation)=>{
          if(operation['estado'] == 'ACTIVA'){
            this.operations[operation['id']] = operation


            let result = {
              operation_name: operation.name,
              state: 'Sin registros',
              non_compliance: 'Sin registros',
              last_update: 'Nunca',
              auditor: 'Sin registro'
            }

            let last = this.getLastAudit(operation.id)

            if(last){
              result.state = last.result;
              result.auditor = last.auditor.name;
            }

            let less_date = -1;

            if(this.non_compliance[operation.id]){
              /*eslint-disable*/
              let current = 0;
              let resolved = 0;

              let nons = this.non_compliance[operation.id]
              Object.keys(nons).forEach((n)=>{
                if(nons[n].close_date == undefined){
                  Object.keys(nons[n].requests).forEach((r)=>{
                    Object.keys(nons[n].requests[r].fields).forEach((f)=>{
                      if(nons[n].requests[r][f]== undefined) return 

                      if(nons[n].requests[r][f].response){  
                        resolved++
                        // check date
                        if(nons[n].requests[r][f].response.timestamp > less_date) {
                          less_date = nons[n].requests[r][f].response.timestamp
                        }
                      }
                      current++
                    })
                  })
                }
              })
              result.non_compliance = "Sí"
              // result.non_compliance = resolved + "/" + current // TODO usar esto bien
            } else {
              result.non_compliance = "No"
              // result.non_compliance = 0 //TODO usar esto bien
            }
            /*eslint-enable*/

            if (less_date == -1 && last) {
              result.last_update = this.dateFormateR(last.end_at)
            } else if(less_date==-1){
              result.last_update = 'Sin registros'
            } else {
              result.last_update = this.dateFormateR(less_date)
            }

            this.report_data[operation.id] = result
          }
        })
        this.trigger.list = true
      })



    })

  },
  methods:{
    triggering(active) {
      Object.keys(this.trigger).forEach((t) => {
        this.trigger[t] = false

        if(typeof active=='object' && !active.includes(t)) this.trigger[t] = true
        if(typeof active=='string' && active == t) this.trigger[t] = true
      });
    },
    dataUpdate(){
      let promises = this.required_data.map((req)=>{
        let db = req; let prop = req
        if (typeof req == 'object') {db = req['db']; prop = req['prop']}

        return getDataFrom(db,true).then((data)=>{
          if(data) this[prop]=data
        }).catch((err) => showError(err,'Hubo un error al obtener información. Presione CANCELAR para reintentar o ACEPTAR para recargar la página.'));
      });

      return Promise.all(promises)
    },
    handleEvent(action,selection){ 
      this.selected = selection
      this.triggering(action) 
    },
    handleEnd(update){
      if (update) {
        this.dataUpdate().then(() => this.triggering('list'))
      } else {
        this.triggering('list')
      }
    },
    getLastAudit(operation_id){
      let last = -1
      let r = null
      Object.keys(this.audits_completed).forEach((a)=>{
        if(this.audits_completed[a].operation_id == operation_id && this.audits_completed[a].end_at > last) {
          r=this.audits_completed[a]
          r['id'] = a
        
        }
      })
      return r
    },
    handleAuditData(data){
      let labels = {
          'not_apply':'No aplica',
          'approved':'Sí',
          'disapproved': 'No'
      }

      let requests = data.requests
      let result = ""

      result += "<table border='1' style='margin: 5px 0;width:100%'>"

      result += '<tr>'
      result += `<th>Campo</th>`
      result += `<th>Cumple</th>`
      result += `<th>Observación</th>`
      result += '</tr>'

      Object.keys(requests).forEach((r,i)=>{
          result += '<tr>'
          result += `<td colspan="3" style="font-weight:bold;padding: 15px 5px;">BLOQUE ${i+1}: ${requests[r].label}</td>`
          result += '</tr>'

          Object.keys(requests[r].fields).forEach((f)=>{
              result += "<tr>"
              
              result += `<td style='padding: 5px;'>${requests[r].fields[f].label}</td>`
              result += `<td style='padding: 5px;'>${labels[requests[r].fields[f].answer]}</td>`

              if(requests[r].fields[f].observation){
                  result += `<td style='padding: 5px;'>${requests[r].fields[f].observation}</td>`
              } else {
                  result += "<td style='padding: 5px;'></td>"
              }

              result += "</tr>"
          })
      })

      result += "</table>"

      this.trigger.list = false
      this.trigger.show = true
      this.$nextTick(()=>{
        document.getElementById('table').innerHTML = result
      })


    },
    get_badge_color(result){
      if(result=='aprobado') return 'success'
      if(result=='desaprobado') return 'danger'
      if(result=='condicional') return 'warning'
    },
    handleDelete(handle,data){
      // Dejar que cada vista lo lleve a su forma este método
      // Quizas se podrían crear 'modes' en utils para eliminar de diferentes formar. Como soft, en cascada, etc
      console.log(handle,data);
    },
    dateFormateR(d){
      return dateFormat(d)
    }
  }
}
</script>

<style>

</style>