<template>
   <button :class="(border) ? 'w-100 text-left bg-transparent text-success p-1 px-2 border border-success rounded' : 'w-100 text-left bg-transparent border-0 text-success'" v-if="state" @click="changeState()">
        <svg class="mr-2 feather feather-toggle-right" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="16" cy="12" r="3"></circle></svg>
        {{estados[0]}}
    </button>
    <button :class="(border) ? 'w-100 text-left bg-transparent text-dark p-1 px-2 border border-dark rounded' : 'w-100 text-left bg-transparent border-0 text-dark'" v-else @click="changeState()">
        <svg class="mr-2 feather feather-toggle-right" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="8" cy="12" r="3"></circle></svg>
        {{estados[1]}}
    </button>
</template>

<script>
export default {
    name: 'ToggleButton',
    props: {estadoInicial:Boolean,estados:Array,border:Boolean},
    data(){
        return {
            state: false
        }
    },
    methods:{
        changeState(){
            this.state = !this.state
            this.$emit('estado', this.state)
        }
    },
    mounted(){
        this.state = (this.estadoInicial != undefined) ? this.estadoInicial : false
    }
}
</script>

<style scoped>
    .boton {
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: start;
        padding-right: .6rem !important;
        padding-left: .6rem !important;
    }

    svg {
        margin-bottom: 2px;
    }
</style>