<template>
  <div class="page-content">
    <!-- Navegación superior tipo breadcrumb -->
    <nav class="page-breadcrumb mb-2" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <router-link :to="{ name: 'Dashboard' }" class="text-dark">Inicio</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ view.title }}
        </li>
      </ol>
    </nav>

    <!-- Card title section -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">

            <!-- Title and actions -->
            <div class="row no-gutters align-items-center mb-3">
              <div class="col-12 col-sm-6 col-md-7">
                <h4 class="mb-2 mb-sm-0">
                  <svg viewBox="0 0 24 24" width="24" height="24" style="vertical-align: top;" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>
                  {{view.title}}
                </h4>
              </div>
              <div class="col-12 col-sm-6 col-md-5 text-left text-sm-right">
                <!-- <button type="button" class="btn btn-outline btn-outline-secondary" @click="triggering('categories')" v-if="!trigger.create && !trigger.categories">
                  Secondary
                </button> -->
                <button type="button" class="btn btn-primary ml-2" @click="triggering('create')" v-if="!trigger.create && !trigger.manage && !trigger.update">
                  Alta de auditores
                  <svg viewBox="0 0 24 24" width="18" height="18" style="vertical-align: middle;" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                
                </button>
                <button class="btn btn-light" v-if="trigger.create || trigger.manage || trigger.update" @click="triggering('list')">&larr; Cancelar</button>
              </div>
            </div>

            <!-- Description -->
            <div class="row mb-4">
              <div class="col">
                <p v-if="view.description">{{view.description}}</p>
                <div class="alert alert-info w-100 mb-0" v-if="view.info">
                  {{view.info}}
                </div>
              </div>
            </div>

            <!-- Modules -->
            <div class="row">

              <div class="col" v-if="trigger.list">
                <List 
                  :data="auditors"
                  :columns="[
                    {
                      'label':'Nombre',
                      'trackBy':'name',
                    },
                    {
                      'label':'Operaciones',
                      'type':'function',
                      'trackBy':'dni',
                      'formatter':getAuditorOperations
                    }
                  ]"
                  :actions="[
                    {
                      'label':'Editar',
                      'emitter':'update',
                      'color':'primary'
                    },
                    {
                      'label':'Gestionar operaciones',
                      'emitter':'manage',
                      'color':'primary'
                    },
                    {
                      'label':'Quitar',
                      'emitter':'remove',
                      'color':'danger',
                    },
                  ]"
                  @manage="handleEvent('manage',$event)"
                  @update="handleEvent('update',$event)"
                  @remove="handleDelete($event)"

                />
              </div>

              <div class="col mt-2" v-if="trigger.create">
                <h5 class="mb-2">
                  <svg viewBox="0 0 24 24" width="16" height="16" style="vertical-align: top;" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                  Alta de auditores
                </h5>

                <Create
                  :what="[
                    {
                      type:'api',
                      api:'https://auth.foodservice.com.ar/?type=personal&access_token=1234567',
                      props:{'leg_numdoc':'dni','leg_numero':'legajo','leg_apellido+leg_nombre':'name'},
                      label:'Seleccione personal desde la nómina',
                    },
                    {
                      type:'mail',
                      label:'Correo electrónico',
                      name:'mail',
                      validate:'mail'
                    },
                  ]"
                  where="auditors"
                  timestamp="created_at"
                  @ended="triggering('list')"
                  @created="setPermissionNewAuditors($event)"
                />
              </div>

              <div class="col mt-2" v-if="trigger.update">
                <h5 class="mb-2">
                  <svg viewBox="0 0 24 24" width="16" height="16" style="vertical-align: top;" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                  Actualización de auditores
                </h5>

                <Update
                  :selected="selected"
                  @ended="triggering('list')"
                />
              </div>

              <div class="col mt-2" v-if="trigger.manage">
                <h5 class="mb-3">
                  <svg viewBox="0 0 24 24" width="16" height="16" style="vertical-align: top;" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                  Asignando operaciones a <u>{{capitalizeEacheR(selected.name)}}</u>
                </h5>

                <ManageOperations
                  :operations="operations"
                  :auditor_operation="auditor_operation"
                  :auditors="auditors"
                  :dni="selected.dni"
                  @ended="dataUpdate().then(() => triggering('list'))"
                />

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getDataFrom,showError,capitalizeEach} from '@/utils';

import firebase from "firebase/app";
import "firebase/database";

const fireAplicativos = firebase.apps[1];


import Create from '@/components/Create.vue';
import ManageOperations from '@/components/Auditors/ManageOperations.new.vue';
import Update from '@/components/Auditors/Update.vue';
// import TreeManager from '@/components/Audit/TreeManager.vue';
import List from '@/components/List.vue';

export default {
  name: "Auditors",
  components:{
    Create,
    List,
    ManageOperations,
    // TreeManager
    Update,
  },
  data(){
    return {
      view:{title:'Auditores',description:'Añada personal para realizar auditorías, además asigne empresas.'},

      required_data:['auditors','auditor_operation', {db:'https://consumos.foodservice.com.ar/api/empresas',prop:'operations'}], // Object {prop,db} or String to get[db] and set this[prop]

      auditors: null, // props
      auditor_operation: null,
      operations:null,

      trigger:{
        create:false,
        update:false,
        list:false,
        manage:false,
      },

      selected:null,
    }
  },
  mounted(){
    this.dataUpdate().then(() => this.triggering('list'))
  },
  methods:{
    setPermissionNewAuditors(auditor){
      fireAplicativos.database().ref('permisos/'+auditor.dni).update({
        "53":{
          "Editor": true,
            "Lector": true,
            "ModulosAccess": "BromatologiaCheck"
        }
      }).then(()=>{
        this.dataUpdate().then(() => this.triggering('list'))
      })

    },
    triggering(active) {
      Object.keys(this.trigger).forEach((t) => {
        this.trigger[t] = false

        if(typeof active=='object' && !active.includes(t)) this.trigger[t] = true
        if(typeof active=='string' && active == t) this.trigger[t] = true
      });
    },
    dataUpdate(){
      let promises = this.required_data.map((req)=>{
        let db = req; let prop = req
        if (typeof req == 'object') {db = req['db']; prop = req['prop']}


        return getDataFrom(db,!String(db).includes('http')).then((data)=>{
          if(data) this[prop]=data
          // console.info('getting data',data,'db,saves into',prop,this[prop]);
        }).catch((err) => showError(err,'Hubo un error al obtener información. Presione CANCELAR para reintentar o ACEPTAR para recargar la página.'));
      });

      return Promise.all(promises)
    },
    handleEvent(action,selection){ 
      this.selected = selection; 
      this.triggering(action) 
    },
    handleEnd(update){
      if (update) {
        this.dataUpdate().then(() => this.triggering('list'))
      } else {
        this.triggering('list')
      }
    },
    // up_and_trigger(prop,data,trigger){
    //   this[prop]=data;
    //   this.triggering(trigger)
    // },
    handleDelete(data){
      // Dejar que cada vista lo lleve a su forma este método
      // Quizas se podrían crear 'modes' en utils para eliminar de diferentes formar. Como soft, en cascada, etc
      console.log(data);

      fireAplicativos.database().ref('permisos/'+data.dni).update({ "53":null }).then(()=>{
        firebase.database().ref('auditors/'+data.id).remove().then(()=>{
          firebase.database().ref('auditor_operation/'+data.dni).remove().then(()=>{
            this.dataUpdate().then(() => this.triggering('list'))
          })
        })
      })
    },
    getAuditorOperations(dni){
      if(this.auditor_operation && this.auditor_operation[dni]){
        return Object.keys(this.auditor_operation[dni]).length
      } else {return 0}
    },
    capitalizeEacheR(s){return capitalizeEach(s)}
  }
}
</script>

<style>

</style>